<template>
  <div class="order">
    <div class="querybox">
      <el-row :gutter="20">
        <el-col :span="6">
          <p>订单编号:</p>
          <el-input
            v-model="queryParams.orderNo"
            placeholder="请输入订单编号"
          ></el-input>
        </el-col>
        <el-col :span="6">
          <p>商品名称:</p>
          <el-input
            v-model="queryParams.goodsName"
            placeholder="请输入商品名称"
          ></el-input>
        </el-col>
        <el-col :span="6">
          <p>收货人:</p>
          <el-input
            v-model="queryParams.name"
            placeholder="请输入收货人姓名"
          ></el-input>
        </el-col>
        <el-col :span="6">
          <p>收货人电话:</p>
          <el-input
            v-model="queryParams.phone"
            placeholder="请输入收货人电话"
          ></el-input>
        </el-col>
        <el-col :span="6">
          <p>订单状态:</p>
          <el-select
            v-model="queryParams.goodsOrderStatus"
            placeholder="请选择订单状态"
            clearable
          >
            <el-option
              v-for="item in getDictDatas(DICT_TYPE.GOODS_ORDER_STATUS).filter(
                (el) => el.value > 0
              )"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="18">
          <div class="btnbox">
            <el-button
              type="primary"
              icon="el-icon-refresh-right"
              class="gray-btn"
              @click="resetFn"
              >重置</el-button
            >
            <el-button
              type="primary"
              icon="el-icon-search"
              class="blue-btn"
              @click="search"
              >查询</el-button
            >
            <el-button
              type="primary"
              icon="el-icon-upload"
              class="green-btn"
              @click="goAdd"
              >添加商品</el-button
            >
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="content">
      <el-table
        :data="tableData"
        tooltip-effect="dark"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column
          prop="orderNo"
          label="订单编号"
          show-overflow-tooltip
          fixed
        >
        </el-table-column>
        <el-table-column
          prop="goodsName"
          label="商品名称"
          show-overflow-tooltip
          fixed
        >
        </el-table-column>
        <el-table-column prop="musicDurationText" label="付款金额">
          <template slot-scope="{ row }"> ￥ {{ row.money }} </template>
        </el-table-column>
        <el-table-column prop="createdTime" label="商品规格"> </el-table-column>
        <el-table-column prop="createdTime" label="下单时间" min-width="120">
        </el-table-column>
        <el-table-column prop="name" label="收货人"> </el-table-column>
        <el-table-column prop="phone" label="收货电话"> </el-table-column>
        <el-table-column label="收货地址">
          <template slot-scope="{ row }">
            <el-button
              class="textblue-btn"
              type="text"
              @click="checkAddress(row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="payType_dictText"
          label="支付方式"
          min-width="100"
        >
        </el-table-column>
        <el-table-column prop="goodsOrderStatus_dictText" label="订单状态">
          <template slot-scope="{ row }">
            <span
              :style="{
                color: row.goodsOrderStatus === 1 ? '#06D0A9' : '#9D29CC',
              }"
              >{{ row.goodsOrderStatus_dictText }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="agentNickname" label="代理商">
          <template slot-scope="{ row }">
            {{ row.agentNickname ? row.agentNickname : "无" }}
          </template>
        </el-table-column>
        <el-table-column prop="agentLabel" label="代理标签">
          <template slot-scope="{ row }">
            {{ row.agentLabel ? row.agentLabel : "无" }}
          </template>
        </el-table-column>
        <el-table-column prop="commissionMoneyString" label="佣金">
          <template slot-scope="{ row }">
            {{ row.commissionMoneyString ? row.commissionMoneyString : 0 }}
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" min-width="120">
          <template slot-scope="{ row }">
            <el-button
              class="blue-btn"
              @click="checkSend(row)"
              v-if="row.goodsOrderStatus === 1"
            >
              发货
            </el-button>
            <el-button
              class="green-btn"
              @click="checkLog(row)"
              v-if="row.goodsOrderStatus === 2"
            >
              查看物流
            </el-button>
            <el-button
              class="textblue-btn"
              type="text"
              @click="editLog(row)"
              v-if="row.goodsOrderStatus === 2"
            >
              修改单号
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <pagination
        v-show="total > queryParams.pageSize"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="init"
      />
    </div>
    <!-- 查看地址 -->
    <el-dialog
      :visible.sync="addressVisible"
      title="查看收货地址"
      width="35%"
      :before-close="handleaddressClose"
      destroy-on-close
      :show-close="false"
    >
      <div slot="title" class="title">
        <p>查看收货地址</p>
        <span @click="handleaddressClose"><i class="el-icon-close"></i></span>
      </div>
      <div class="address-box">
        <el-button
          type="text"
          class="textblue-btn copy-btn"
          @click="copyAddress"
          :data-clipboard-text="coord"
          >一键复制</el-button
        >
        <div class="name">
          <span>收货人</span>
          <p>{{ info.name }}</p>
        </div>
        <div>
          <span>收货人联系方式</span>
          <p>{{ info.phone }}</p>
        </div>
        <div>
          <span>下单人地址</span>
          <p>{{ info.address }}</p>
        </div>
      </div>
    </el-dialog>
    <!-- 发货 -->
    <el-dialog
      :visible.sync="sendVisible"
      title="发货"
      width="30%"
      :before-close="handlesendClose"
      destroy-on-close
      :show-close="false"
    >
      <div slot="title" class="title">
        <p>{{ this.isType === "add" ? "发货" : "修改单号" }}</p>
        <span @click="handlesendClose"><i class="el-icon-close"></i></span>
      </div>
      <div class="address-box">
        <div class="name">
          <span>快递公司</span>
          <el-select v-model="expressCode" placeholder="请选择">
            <el-option
              v-for="item in expressList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div v-if="expressCode == 8">
          <span>手机尾号4位</span>
          <el-input
            v-model="phoneNo"
            placeholder="请输入手机尾号4位"
          ></el-input>
        </div>
        <div>
          <span>快递单号</span>
          <el-input v-model="expressNo" placeholder="请输入快递单号"></el-input>
        </div>
      </div>
      <div class="btn-box">
        <el-button type="primary" class="gray-btn" @click="handlesendClose"
          >取消</el-button
        >
        <el-button type="primary" class="blue-btn" @click="goSend"
          >确定</el-button
        >
      </div>
    </el-dialog>
    <!-- 查看物流 -->
    <el-dialog
      :visible.sync="logisticsVisible"
      title="查看物流"
      width="35%"
      :before-close="handlelogisticsClose"
      destroy-on-close
      :show-close="false"
    >
      <div slot="title" class="title">
        <p>查看物流</p>
        <span @click="handlelogisticsClose"><i class="el-icon-close"></i></span>
      </div>
      <div class="log-box">
        <div class="log-name">
          <p><img :src="logInfo.logo" alt="" /></p>
          <h4>{{ logInfo.name }}{{ logInfo.orderNo }}</h4>
        </div>
        <div class="log-steps">
          <el-steps
            class="custom-steps"
            direction="vertical"
            process-status="finish"
            :active="0"
          >
            <el-step
              v-for="(item, index) in steps"
              :icon="!index ? 'el-icon-document-checked' : 'el-icon-success'"
              :key="index"
              :title="item.text"
            >
              <div slot="description">
                <p class="row-time">{{ item.desc }}</p>
              </div>
            </el-step>
          </el-steps>
        </div>
        <div class="log-basic">
          [收货人信息] {{ info.name }}, {{ info.phone }}, {{ info.address }}
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getOrderList,
  getExpressList,
  getDelivery,
  checkExpress,
  editCode,
} from "@/api/goods";
import ClipboardJS from "clipboard";
export default {
  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        orderNo: "",
        goodsName: "",
        name: "",
        phone: "",
        goodsOrderStatus: "",
      },
      tableData: [],
      total: 0, //总数
      // 发货
      sendVisible: false,
      expressList: [],
      allList: [],
      expressCode: null,
      expressNo: "",
      phoneNo: "",
      orderId: null,
      // 查看收货地址
      addressVisible: false,
      info: {},
      coord: "",
      // 查看物流
      logisticsVisible: false,
      steps: [],
      logInfo: {},
      isType: "add",
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    //获取订单列表
    async init() {
      const res = await getOrderList(this.queryParams);
      this.tableData = res.result.records;
      this.total = res.result.total;
    },
    // 查询
    search() {
      this.queryParams.pageNum = 1;
      this.init();
    },
    //重置
    resetFn() {
      this.queryParams.goodsName = "";
      this.queryParams.orderNo = "";
      this.queryParams.name = "";
      this.queryParams.phone = "";
      this.queryParams.goodsOrderStatus = "";
      this.queryParams.pageNum = 1;
      this.init();
    },
    // 发货
    async checkSend(item) {
      const res = await getExpressList();
      this.allList = res.result;
      this.expressList = res.result.map((el) => {
        return {
          label: el.title,
          value: el.id,
        };
      });
      this.orderId = item.id;
      this.isType = "add";
      this.sendVisible = true;
    },
    handlesendClose() {
      this.sendVisible = false;
    },
    // 发货
    async goSend() {
      if (!this.expressCode) {
        this.$message.error("请选择快递公司");
        return;
      }
      if (!this.expressNo) {
        this.$message.error("请输入快递单号");
        return;
      }

      if (this.expressCode == 8 && !this.phoneNo) {
        this.$message.error("顺丰快递必须添加收或寄件人手机尾号4位");
        return;
      }
      const arr = this.allList.filter((el) => el.id === this.expressCode);
      // console.log(this.expressCode, this.expressNo, this.expressList);
      const expressCode = arr[0].code;
      const params = {
        expressCode,
        expressNo:
          expressCode === "SF"
            ? `${this.expressNo}:${this.phoneNo}`
            : this.expressNo,
        id: this.orderId,
      };

      if (this.isType === "add") {
        await getDelivery(params);
        this.$message.success("订单发货成功！");
        this.sendVisible = false;
        this.init();
      } else {
        await editCode(params);
        this.$message.success("发货订单修改成功！");
        this.sendVisible = false;
        this.init();
      }
    },
    // 查看收货地址
    checkAddress(item) {
      this.info = item;
      this.coord = `收件人: ${item.name},手机号码: ${item.phone},详细地址: ${item.address}`;
      this.addressVisible = true;
    },
    handleaddressClose() {
      // this.clipboard.destroy();
      this.addressVisible = false;
    },
    // 复制地址
    copyAddress() {
      this.clipboard = new ClipboardJS(".copy-btn");
      this.clipboard.on("success", (e) => {
        console.log(e);
        console.info("Text:", e.text);
        this.$message.info("复制成功");
        e.clearSelection();
      });

      this.clipboard.on("error", (e) => {
        if (!e.text) this.$message.error("暂无可复制的内容");
      });
    },
    // 查看物流
    async checkLog(item) {
      this.info = item;
      const res = await checkExpress(item.expressNo, item.expressCode);
      let result = JSON.parse(res.result);
      let steps = [];
      if (result.code === "OK") {
        steps = result.list.map((el) => {
          return {
            text: el.content,
            desc: el.time,
          };
        });
      } else {
        steps = [
          {
            text: result.msg,
            desc: result.time,
          },
        ];
      }
      result.orderNo = result.no.split(":")[0];
      this.logInfo = result;
      this.steps = steps;
      this.logisticsVisible = true;
    },
    // 修改单号
    async editLog(item) {
      const res = await getExpressList();
      this.allList = res.result;
      this.expressList = res.result.map((el) => {
        return {
          label: el.title,
          value: el.id,
        };
      });
      this.expressCode = res.result.filter(
        (el) => el.code === item.expressCode
      )[0].id;
      this.orderId = item.id;
      const noArr = item.expressNo.split(":");
      this.expressNo = noArr[0];
      if (noArr.length > 1) {
        this.phoneNo = noArr[1];
      }
      this.isType = "edit";
      this.sendVisible = true;
    },
    handlelogisticsClose() {
      this.logisticsVisible = false;
    },
    // 新增商品
    goAdd() {
      this.$router.push("/addGoods");
    },
  },
};
</script>
<style lang="scss" scoped>
.order {
  .querybox {
    width: 100%;
    border-bottom: 1px solid #0000000d;
    ::v-deep .el-row {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .el-col {
        display: flex;
        align-items: center;
        margin-bottom: 22px;
        p {
          color: #4e5969;
          line-height: 22px;
          margin-right: 16px;
          white-space: nowrap;
        }
        .el-input,
        .el-range-editor {
          width: 280px;
        }
        input::-webkit-input-placeholder {
          /* WebKit browsers */
          color: #86909c;
        }
        .el-input__inner,
        .el-range-input {
          background: #f7f8fa;
          border: none;
          color: #86909c;
        }
      }
    }
  }
  .btnbox {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    .el-button {
      padding: 11px 0;
      width: 105px;
      margin-left: 22px;
    }
  }
  .content {
    padding-top: 20px;
    .blue-btn,
    .green-btn {
      padding: 5px 0;
      width: 78px;
    }
  }
  .address-box {
    position: relative;
    > .textblue-btn {
      position: absolute;
      top: 0;
      right: 0;
    }
    > ::v-deep div {
      margin-top: 16px;
      span {
        font-size: 16px;
        color: #333333;
        line-height: 22px;
        margin-bottom: 16px;
        display: block;
      }
      p {
        padding: 12px 22px;
        background: #f5f6f6;
        box-sizing: border-box;
        font-size: 16px;
        color: #333333;
        line-height: 22px;
        font-weight: bold;
      }
      .el-input,
      .el-range-editor,
      .el-select {
        width: 100%;
      }
      input::-webkit-input-placeholder {
        /* WebKit browsers */
        color: #86909c;
      }
      .el-input__inner,
      .el-range-input {
        background: #f7f8fa;
        border: none;
        color: #86909c;
        font-size: 16px;
        color: #333333;
        line-height: 22px;
        font-weight: bold;
      }
    }
    .name {
      margin: 0;
    }
  }
  .btn-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 80px;
    .el-button {
      width: 105px;
      border-radius: 6px;
      padding: 11px 0;
    }
  }
  .log-name {
    display: flex;
    align-items: center;
    p {
      width: 38px;
      height: 38px;
      margin-right: 10px;
      border-radius: 100%;
      img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
      }
    }
    h4 {
      font-weight: 600;
      font-size: 15px;
      color: #161616;
      line-height: 21px;
    }
  }
  .log-steps {
    margin: 20px 0 18px;
    max-height: 300px;
    overflow-y: scroll;
  }
  .log-basic {
    display: flex;
    padding: 10px;
    background: #f5f6f6;
    border-radius: 6px;
    font-weight: 400;
    font-size: 14px;
    color: #161616;
    line-height: 20px;
  }
}
/* 自定义步骤条的背景色 */
::v-deep .custom-steps {
  .el-step__head {
    padding-bottom: 14px;
  }
  .el-step__main {
    margin-bottom: 14px;
    .is-wait {
      line-height: 20px;
      font-weight: 400;
      font-size: 14px;
      color: #8f908f;
      line-height: 20px;
    }
    .row-time {
      font-weight: 400;
      font-size: 14px;
      color: #8f908f;
      line-height: 20px;
      margin-top: 6px;
    }
  }
}
::-webkit-scrollbar:vertical {
  width: 5px;
  color: red;
}
::-webkit-scrollbar {
  width: 2px;
  // color: red;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(229, 234, 237, 0.1);
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}
</style>
